$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1280px;

$max-mobile: (max-width: $tablet-width - 1px,
);

$min-tablet: (min-width: $tablet-width,
);

$min-desktop: (min-width: $desktop-width,
);

body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

h1 {
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 1.7em;
}

.container {
  width: 320px;
  margin: 0 auto;

  @media #{$min-tablet} {
    width: 708px;
    padding: 0 30px;
  }

  @media #{$min-desktop} {
    width: 1200px;
    padding: 0 40px;
  }
}

section:not(:last-of-type) {
  border-bottom: 1px solid #ccc;
}

.section-title {
  position: relative;
  padding-left: 20px;
  margin: 30px 0;
  text-transform: uppercase;

  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 40px;
    height: 40px;
  }
}

.page-header {
  height: 200px;
  background-image: url(../img/bg_header@2x.jpg);
  background-size: 400px 200px;
  background-position: center;

  &__menu-wrap {
    width: 100%;
  }

  @media #{$min-tablet} {
    display: flex;
    align-items: flex-end;

    &__menu-wrap {
      background: linear-gradient(#00000020, #00000099);
    }
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 275px;

  @media #{$min-tablet} {
    top: -20px;
  }
}


.svg-menu-toggle {
  fill: #fff;
  pointer-events: all; //needs to be there so the hover works

  .bar {
    -webkit-transform: rotate(0) translateY(0) translateX(0);
    transform: rotate(0) translateY(0) translateX(0);
    opacity: 1;
    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;
    -webkit-transition: -webkit-transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
  }

  .bar:nth-of-type(1) {
    -webkit-transform-origin: 20px 10px;
    transform-origin: 20px 10px;
  }

  .bar:nth-of-type(3) {
    -webkit-transform-origin: 20px 20px;
    transform-origin: 20px 20px;
  }
}


.menu-toggle.open .svg-menu-toggle {
  .bar:nth-of-type(1) {
    -webkit-transform: rotate(-45deg) translateY(0) translateX(0);
    transform: rotate(-45deg) translateY(0) translateX(0);
  }

  .bar:nth-of-type(2) {
    opacity: 0;
  }

  .bar:nth-of-type(3) {
    -webkit-transform: rotate(45deg) translateY(0em) translateX(0em);
    transform: rotate(45deg) translateY(0em) translateX(0em);
  }
}

.inline-svg {
  display: block;
  margin: 0 auto;
}

.main-menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: #000;

  &__list {
    padding: 0;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  &__item {
    border-top: 1px solid #fff;

    &:last-child {
      border-bottom: 1px solid #fff;
    }
  }

  &__link {
    display: flex;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-size: 2em;
    line-height: 40px;
    transition: 0.2s;

    svg {
      margin-right: 20px;
      width: 40px;
      height: 40px;
      fill: currentColor;
    }

    &:hover,
    &:focus {
      color: #bbb;
    }
  }

  @media #{$min-tablet} {
    top: unset;
    left: unset;
    position: relative;
    opacity: 1;
    visibility: visible;
    background: unset;

    &__list {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-end;
    }

    &__item {
      border: 0 !important;
    }
  }
}

.menu-toggle {
  width: 64px;
  position: relative;
  z-index: 2;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open+.main-menu {
    opacity: 1;
    visibility: visible;
  }

  @media #{$min-tablet} {
    display: none;
  }
}

.promo {
  &__title {
    margin-top: 40px;
  }

  &__text {
    font-size: 1.5em;
    text-align: center;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item-text {
    font-size: 1.5em;
    text-transform: uppercase;
    color: green;
  }

  &__img {
    margin: auto 0;
    width: 150px;
  }
}

.documents {
  &__title {
    position: relative;
  }

  &__list {
    list-style: none;
    margin-top: 30px;
    padding: 0;
  }

  &__link {
    display: block;
    position: relative;
    font-size: 1.7em;
    padding: 10px 0;
    padding-left: 40px;
    margin: 10px 20px;
    color: blue;
    transition: 0.2s;

    &:hover {
      color: #4762ff;
      text-decoration: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 30px;
      height: 30px;
      background-image: url(../img/svg/pdf.svg);
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.contacts {
  padding-bottom: 40px;

  &__info {
    text-align: center;
    font-size: 1.3em;
  }

  &__title {
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__adress {
    margin-top: 5px;
  }

  &__link {
    font-size: 1.4em;
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
  }
}
